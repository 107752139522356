import React from "react";
import { Box, Typography } from "@mui/material";

const SalesBanner = () => {
  return (
    <Box
      sx={{
        width: "100%",
        backdropFilter: "blur(8px)",
        boxShadow: "0px 1px 8px 0px rgba(0,0,0,0.28)",
        backgroundColor: "#f42a2ab8",
        color: "#fff",
        padding: "12px 0",
        position: "fixed",
        bottom: 0,
        overflow: "hidden",
        whiteSpace: "nowrap",
        display: "flex",
        justifyContent: "center",
        zIndex: 4
      }}
    >
      <Typography
        variant="h6"
        sx={{
          animation: "marquee 15s linear infinite",
          fontWeight: 500,
          fontSize: "12px",
          textTransform: "uppercase",
          letterSpacing: "2px",
        }}
      >
        ✨ Just nu | REA | Endast 20kr för 10 SMS ✨
      </Typography>
    </Box>
  );
};

// CSS för att skapa marquee-effekten
const styles = `
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`;

// Lägg till CSS-animationen dynamiskt i <head>
const addStyles = () => {
  const styleElement = document.createElement("style");
  styleElement.innerHTML = styles;
  document.head.appendChild(styleElement);
};

addStyles();

export default SalesBanner;
