import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import PhoneInput from "./PhoneInput";
import Team from "./Wizard/Team";
import SelectableCard from "./SelectableCard";
import Frequency from "./Wizard/Frequency";
import teams from "../data/teams.json";
import { frequencyValues } from "../consts/form";
import Terms, { TermsEnum } from "./Terms";
import { useCheckout } from "./CheckoutProvider";

interface Props {
  open: boolean;
  onClose: () => void;
}

const DialogForm = ({ open, onClose }: Props) => {
  const [step, setStep] = useState(0);
  const [fade, setFade] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const { methods, onSubmit } = useCheckout();

  const sender_team_id = methods.watch("sender_team_id");
  const receiver_team_id = methods.watch("receiver_team_id");
  const phone_number = methods.watch("phone_number");
  const frequency = methods.watch("frequency");
  const number_of_messages = methods.watch("number_of_messages");
  const sort_order = methods.watch("sort_order");

  const handleNext = () => {
    if (step < 6) {
      setFade(false); // Start fading out
    }
  };

  const renderInput = () => {
    switch (step) {
      case 0:
        return <Team name="sender_team_id" label="Vilket lag håller du på?" />;
      case 1:
        return (
          <Team name="receiver_team_id" label="Vilket lag håller din vän på?" />
        );
      case 2:
        return (
          <>
            <Typography variant="h5" gutterBottom>
              Vad är din väns telefonnummer?
            </Typography>
            <PhoneInput name="phone_number" />
          </>
        );
      case 3:
        return <Frequency />;
      case 4:
        return (
          <>
            <Typography variant="h5" gutterBottom>
              Hur många SMS vill du skicka?
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <SelectableCard
                id={10}
                title="10"
                price={30}
                color="#1DB45A"
                narrow
              />
              <SelectableCard
                id={20}
                title="20"
                price={40}
                color="#1976d2"
                narrow
              />
              <SelectableCard
                id={30}
                title="30"
                price={50}
                color="#1976d2"
                narrow
              />
            </Box>
          </>
        );
      case 5:
        return (
          <>
            <Typography variant="h5" gutterBottom>
              I vilken ordning ska resultaten skickas?
            </Typography>
            <FormControl sx={{ my: 2 }} fullWidth>
              <InputLabel>Sorteringsordning</InputLabel>
              <Controller
                name="sort_order"
                control={methods.control}
                defaultValue="random"
                render={({ field }) => (
                  <Select {...field} label="Sorteringsordning">
                    <MenuItem value="asc">Äldst segrar först</MenuItem>
                    <MenuItem value="desc">Nyast segrar först</MenuItem>
                    <MenuItem value="random">Slumpmässig</MenuItem>
                  </Select>
                )}
              />
              <FormHelperText>
                Välj sorteringsordning för matcherna
              </FormHelperText>
            </FormControl>
          </>
        );
      case 6:
        return (
          <>
            <Typography sx={{ my: 2 }} variant="h3">
              Sammanfattning
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 800 }} component="th">
                      Vinnande lag
                    </TableCell>
                    <TableCell>
                      {
                        teams.find((team) => team.team_id === sender_team_id)
                          ?.team_name
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 800 }} component="th">
                      Förlorande lag
                    </TableCell>
                    <TableCell>
                      {
                        teams.find((team) => team.team_id === receiver_team_id)
                          ?.team_name
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 800 }} component="th">
                      Mottagarens telefonnummer
                    </TableCell>
                    <TableCell>{phone_number}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 800 }} component="th">
                      Sändningsfrekvens
                    </TableCell>
                    <TableCell>
                      {
                        frequencyValues.find((freq) => freq.value === frequency)
                          ?.label
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 800 }} component="th">
                      Antal SMS
                    </TableCell>
                    <TableCell>{number_of_messages} SMS</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 800 }} component="th">
                      Ordning
                    </TableCell>
                    <TableCell>
                      {sort_order === "random"
                        ? "Slumpmässigt"
                        : sort_order === "asc"
                        ? "Äldst segrar först"
                        : "Nyast segrar först"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography
              sx={{ display: "block" }}
              variant="caption"
            >
              Genom att använda vår tjänst godkänner du våra{" "}
              <Terms terms={TermsEnum.USER} />
              {", "}
              <Terms terms={TermsEnum.PURCHASE} />
              {" och "}
              vår <Terms terms={TermsEnum.PRIVACY} />
            </Typography>
            <Button sx={{ mt: 2 }} variant="contained" type="submit">
              Beställ
            </Button>
          </>
        );
    }
  };

  useEffect(() => {
    if (!fade) {
      const timeout = setTimeout(() => {
        setStep((prevStep) => prevStep + 1);
        setFade(true); // Start fading in after the timeout
      }, 300); // Match this duration with the Fade duration

      return () => clearTimeout(timeout);
    }
  }, [fade]);

  useEffect(() => {
    switch (step) {
      case 0:
        setDisabled(!sender_team_id);
        break;
      case 1:
        setDisabled(!receiver_team_id);
        break;
      case 2:
        setDisabled(phone_number?.length < 12);
        break;

      default:
        break;
    }
  }, [methods, receiver_team_id, phone_number, sender_team_id, step]);

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "none",
          maxWidth: "100%",
          width: "100%",
          height: "100%",
        }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <DialogContent sx={{ textAlign: "center", p: 5 }}>
          <FormProvider {...methods}>
            <Fade in={fade} timeout={300}>
              <div>{renderInput()}</div>
            </Fade>
          </FormProvider>
        </DialogContent>
        <DialogActions sx={{ display: "block" }}>
          <Box width="100%">
            <LinearProgress
              variant="determinate"
              value={((step + 1) / 7) * 100}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "1.5em",
            }}
          >
            <Button
              disabled={step === 0}
              color="secondary"
              onClick={() => setStep(step - 1)}
            >
              Tillbaka
            </Button>
            <Button
              disabled={disabled}
              variant="contained"
              color="primary"
              onClick={handleNext}
              type={step === 6 ? "submit" : undefined}
            >
              {step === 6 ? "Beställ" : "Nästa"}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogForm;
