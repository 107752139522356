import {
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { frequencyValues } from "../../consts/form";
import { useFormContext } from "react-hook-form";

const Frequency = () => {
  const {
    setValue,
    watch
  } = useFormContext();
  const selectedFrequency = watch("frequency");

  const handleFrequencySelect = (frequency: number) => {
    setValue("frequency", frequency);
  };
  return (
    <Container
      maxWidth="sm"
      style={{ textAlign: "center", paddingTop: "2rem" }}
    >
      <Typography variant="h5" gutterBottom>
        Hur ofta ska SMS:en skickas?
      </Typography>

      <Grid container spacing={2}>
        {frequencyValues.map((frequency) => (
          <Grid item xs={6} sm={4} key={frequency.value}>
            <Card
              variant="outlined"
              style={{
                border:
                  selectedFrequency === frequency.value
                    ? "2px solid black"
                    : "1px solid lightgray",
              }}
            >
              <CardActionArea onClick={() => handleFrequencySelect(frequency.value)}>
                <CardContent>
                  <Typography variant="body1">{frequency.label}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Frequency;
