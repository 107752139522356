import { Controller } from "react-hook-form";
import TeamSelect from "./TeamSelect";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import PhoneInput from "./PhoneInput";
import { frequencyValues } from "../consts/form";
import SelectableCard from "./SelectableCard";
import { useState } from "react";
import DiscountCode from "./DiscountCode";
import Terms, { TermsEnum } from "./Terms";
import { Status, useCheckout } from "./CheckoutProvider";


const CheckoutForm = () => {
    const { status, onSubmit, methods } = useCheckout();
    const [discount, setDiscount] = useState(0);
    const [discountType, setDiscountType] = useState<"percentage" | "fixed">(
      "fixed"
    );
  const applyDiscount = (
    discountAmount: number,
    type: "percentage" | "fixed"
  ) => {
    setDiscountType(type);
    setDiscount(discountAmount);
  };
  const calculatePrice = (basePrice: number) => {
    if (discountType === "percentage") {
      return basePrice * (1 - discount / 100);
    } else if (discountType === "fixed") {
      return basePrice - discount;
    }
    return basePrice;
  };

  return (
    <form
      id="form"
      style={{ paddingTop: "3rem" }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <h1>Beställ SMS</h1>
      <TeamSelect label="Vinnande lag" name="sender_team_id" />
      <TeamSelect label="Förlorande lag" name="receiver_team_id" />
      <FormControl sx={{ my: 2 }} fullWidth>
        <InputLabel>Sorteringsordning</InputLabel>
        <Controller
          name="sort_order"
          control={methods.control}
          defaultValue="random"
          render={({ field }) => (
            <Select {...field} label="Sorteringsordning">
              <MenuItem value="asc">Äldst segrar först</MenuItem>
              <MenuItem value="desc">Nyast segrar först</MenuItem>
              <MenuItem value="random">Slumpmässig</MenuItem>
            </Select>
          )}
        />
        <FormHelperText>Välj sorteringsordning för matcherna</FormHelperText>
      </FormControl>
      <PhoneInput name="phone_number" />
      <FormControl sx={{ my: 2 }} fullWidth>
        <InputLabel>Frekvens</InputLabel>
        <Controller
          name="frequency"
          control={methods.control}
          defaultValue={60}
          render={({ field }) => (
            <Select {...field} label="Frekvens">
              {frequencyValues.map(({ value, label }) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>Hur ofta ska SMS:en skickas</FormHelperText>
      </FormControl>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <SelectableCard
          id={10}
          title="10"
          price={calculatePrice(30)}
          color="#1DB45A"
        />
        <SelectableCard
          id={20}
          title="20"
          price={calculatePrice(40)}
          color="#1976d2"
        />
        <SelectableCard
          id={30}
          title="30"
          price={calculatePrice(50)}
          color="#1976d2"
        />
      </Box>
      <Typography variant="caption">Alla priser inklusive moms</Typography>
      <DiscountCode applyDiscount={applyDiscount} />
      <Box height={50} />
      <Typography variant="caption">
        Genom att använda vår tjänst godkänner du våra{" "}
        <Terms terms={TermsEnum.USER} />
        {", "}
        <Terms terms={TermsEnum.PURCHASE} />
        {" och "}
        vår <Terms terms={TermsEnum.PRIVACY} />
      </Typography>
      <Box height={20} />
      <Button
        type="submit"
        disabled={status !== Status.PENDING && status !== Status.CANCELED}
        variant="contained"
      >
        Börja skicka SMS
      </Button>
    </form>
  );
};

export default CheckoutForm;
