import { Box } from "@mui/material";
import SalesBanner from "./SalesBanner";

const Header = () => {
  return (
    <>
    <SalesBanner />
      <Box
        component="header"
        position="fixed"
        width="auto"
        padding={1}
        zIndex={3}
      >
        <a href="/">
          <img
            style={{ width: 30, height: "auto" }}
            src="/logo.png"
            alt="Finten.se"
          ></img>
        </a>
      </Box>
      <Box height={90} />
    </>
  );
};

export default Header;
