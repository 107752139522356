import React, { useState, useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";

const CookieConsent = () => {
  const [consent, setConsent] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  const handleClickOpen = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const savedConsent = localStorage.getItem("fbConsent");
    if (savedConsent === "granted") {
      ReactPixel.grantConsent();
      setConsent("granted")
    } else if (savedConsent === "revoked") {
      ReactPixel.revokeConsent();
      setConsent("revoked")
    }
  }, []);

  const handleAccept = () => {
    ReactPixel.grantConsent();
    localStorage.setItem("fbConsent", "granted");
    setConsent("granted");
  };

  const handleDecline = () => {
    ReactPixel.revokeConsent();
    localStorage.setItem("fbConsent", "revoked");
    setConsent("revoked");
  };

  if (consent === "granted" || consent === "revoked") {
    return null; // Hide the banner if consent is already given or revoked
  }

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          backgroundColor: "rgba(255,255,255,0.8)",
          color: "#000",
          padding: "16px",
          textAlign: "center",
          zIndex: 1300,
          boxSizing: "border-box",
          backdropFilter: "blur(8px)",
          boxShadow: "0px 1px 8px 0px rgba(0,0,0,0.28)",
        }}
      >
        <Typography variant="body1">
          Den här sidan använder{" "}
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={(e) => handleClickOpen(e)}
          >
            cookies
          </span>{" "}
          för att förbättra din upplevelse och för att spåra användning i
          marknadsföringssyfte. Godkänner du användning av cookies?
        </Typography>
        <Box sx={{ marginTop: "8px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAccept}
            sx={{ marginRight: "8px" }}
          >
            Godkänn
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleDecline}>
            Neka
          </Button>
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Cookiepolicy</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Vi använder cookies för att förbättra din upplevelse på vår
            webbplats och för att visa personliga annonser. Nedan listas de cookies som används:
          </Typography>
          <br />
          <Divider />
          <br />
          <Typography  variant="h6">_fbp (Facebook Pixel)</Typography>
          <Typography variant="body2">
            Används för att visa riktade annonser på andra webbplatser. Lagrar
            information om din aktivitet på vår webbplats.
          </Typography>
          <Typography variant="body2">Varaktighet: 90 dagar</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Stäng</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CookieConsent;
