import { Box, Button, Link, Typography } from "@mui/material";
import DialogForm from "./DialogForm";
import { useState } from "react";

const headlinePairs = [
  ["Dominera Fotbollssnacket!", "SMS-bomba din vän för bara 30 kr!"],
  ["Trött på Att Förlora Diskussionen?", "Ta övertaget med våra SMS!"],
  ["Bli Mästare i Prank-Wars!", "Skicka fotbollsvinster för 30 kr!"],
  ["Krossa Din Vän i Fotbollssnack!", "Använd vår SMS-bombningstjänst nu!"],
  ["Få Din Vän att Ge Upp!", "SMS-bomba med lagens vinster för 30 kr!"],
  [
    "Ta Hem Segern Utanför Planen!",
    "Skicka fotbollsvinster direkt till din vän!",
  ],
  ["Gör Dina Prank-Wars Episk!", "SMS-bomba för bara 30 kr!"],
  ["Få Vännens Blod att Koka!", "Skicka fotbollsvinster nu!"],
  [
    "Öka Spänningen i Fotbollssnacket!",
    "SMS-bomba för 30 kr och se reaktionen!",
  ],
  ["Få Vännen att Krypa Till Korset!", "Skicka SMS med ditt lags vinster nu!"],
];

const getRandomHeadlinePair = () => {
  const randomIndex = Math.floor(Math.random() * headlinePairs.length);
  return headlinePairs[randomIndex];
};

const Start = () => {
  const [open, setOpen] = useState(false);
  const [headline1, headline2] = getRandomHeadlinePair();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
    >
      <Typography
        variant="h1"
        fontWeight={800}
        textAlign="center"
        fontSize={20}
        width="90%"
        maxWidth={400}
        mb={2}
      >
        <Typography fontSize={25} color="primary" fontWeight="inherit">
          {headline1}
        </Typography>
        {headline2}
      </Typography>
      <Typography width="90%" maxWidth={600} mb={1}>
        Har du tröttnat på de gamla vanliga skämten? Stöttar din vän helt enkelt
        fel lag i allsvenskan? Finten är nu här för att ta dina prank-wars till
        nästa nivå! Mata in ditt och polarens favoritlag, välj antal och hur
        ofta för SMS-bombningen - allt för enbart 30 spänn.
      </Typography>

      <Typography width="90%" maxWidth={600}>
        Vår app skickar SMS med ditt lags alla vinster vs. kompisens lag - det
        enda du behöver göra är att luta dig tillbaks och se frustrationen växa.
        Snabbt, enkelt och rätt i mål för dig som älskar fotboll och goda
        skratt.
      </Typography>
      <Button sx={{ my: 2 }} variant="outlined" onClick={() => setOpen(true)}>
        Börja skicka SMS
      </Button>
      <Link href="/unsubscribe">Avprenumerera</Link>
      <DialogForm open={open} onClose={() => setOpen(false)} />
    </Box>
  );
};

export default Start;
