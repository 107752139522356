import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Radio,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { frequencyValues } from "../consts/form";

interface Props {
  id: number;
  title: string;
  price: number;
  color: string;
  narrow?: boolean;
}

function SelectableCard({ id, title, price, color, narrow }: Props) {
  const {
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const [frequency, setFrequency] = useState<string>();
  const [error, setError] = useState(false);
  const selectedCard = watch("number_of_messages");
  const frequencyWatch = watch("frequency");

  const handleClick = () => {
    setValue("number_of_messages", id);
    clearErrors("number_of_messages");
  };

  useEffect(() => {
    const label = frequencyValues.find(
      ({ value }) => value === frequencyWatch
    )?.label;
    setFrequency(label ?? "varje halvtimme");
  }, [frequencyWatch]);

  useEffect(() => {
    setError(!!errors?.number_of_messages);
  }, [errors?.number_of_messages]);

  return (
    <Card
      onClick={handleClick}
      sx={{
        boxShadow:
          selectedCard === id
            ? "0px 0px 5px 1px rgb(25 118 210 / 100%),0px 1px 1px 0px rgb(25 118 210 / -10%),0px 1px 3px 0px rgba(0,0,0,0.12)"
            : !error
            ? ""
            : "0px 0px 5px 1px rgb(255 0 0 / 100%),0px 1px 1px 0px rgb(255 0 0 / -10%),0px 1px 3px 0px rgba(0,0,0,0.12)",
        cursor: "pointer",
        m: 2,
        textAlign: "center",
      }}
    >
      <CardHeader sx={{ mb: narrow ? 0 : 1}} title={`💬 ${title} sms`}></CardHeader>
      <CardContent sx={{ py: narrow ? 0 : 1 }}>
        <p style={{ margin: narrow ? 0 : 16 }}>
          Mottagaren får ett sms {frequency} tills {title} sms har skickats.
        </p>
        <Typography sx={{ color, fontWeight: 800, fontSize: "2rem" }}>
          <Typography
            sx={{
              textDecoration: "line-through",
              fontSize: "1rem",
              display: "block",
              fontWeight: 800,
              color: "#000",
            }}
          >
            {price}
            <span style={{ fontSize: "1rem", color: "#000" }}>kr</span>
          </Typography>
          <span style={{ fontWeight: 800, fontSize: "2rem" }}>
            {price - 10}
          </span>
          <span style={{ fontSize: "1rem", color: "#000" }}>kr</span>
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "center", pt: narrow ? 0 : 1 }}>
        <Radio checked={selectedCard === id} />
      </CardActions>
    </Card>
  );
}

export default SelectableCard;
