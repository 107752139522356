import {
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import teams from "../../data/teams.json";
import { useFormContext } from "react-hook-form";

interface Props {
    name: string;
    label: string;
}

const Team = ({ name, label }: Props) => {
    const {
        setValue,
        watch,
        getValues
      } = useFormContext();
      const selectedTeam = watch(name);

  const handleTeamSelect = (teamId: number) => {
    setValue(name, teamId);
  };

  const isDisabled = (team_id: number) => {
    if (name === "receiver_team_id") {
      return getValues("sender_team_id") === team_id;
    }
    return false;
  };
  
  return (
    <Container
      maxWidth="sm"
      style={{ textAlign: "center", paddingTop: "2rem" }}
    >
      <Typography variant="h5" gutterBottom>
        {label}
      </Typography>

      <Grid container spacing={2}>
        {teams.map((team) => (
          <Grid item xs={6} sm={4} key={team.team_id}>
            <Card
              variant="outlined"
              style={{
                color: isDisabled(team.team_id) ? 'lightgray' : 'inherit',
                border:
                  selectedTeam === team.team_id
                    ? "2px solid black"
                    : "1px solid lightgray",
              }}
            >
              <CardActionArea disabled={isDisabled(team.team_id)} onClick={() => handleTeamSelect(team.team_id)}>
                <CardContent sx={{p: "6px"}}>
                  <Typography sx={{fontSize: 12}} variant="body1">{team.team_name}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
export default Team;
